import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import { Row, Col } from 'styled-bootstrap-grid';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import LazyLoad from 'react-lazyload';
import MediaImage from '../components/images/mediaImage';
const PublicationContainer = styled.div`
  margin-bottom: 100px;
  text-align: center;

  & > a {
    text-decoration: none;
    &:hover {
      color: red;
    }
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;
// const SingleMedia = styled.div`
//   margin-bottom: 144px;

//   & a {
//     §text-decoration: none;
//     color: red;
//   }
// `;

const MediaTitle = styled.div`
  text-align: center;

  color: #000;

  padding: 30px 0 0 0;
  span {
    color: red;
  }
  font-size: 28px;
  & h1 {
    display: inline;
  }
  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const PublicationTitle = styled.h1`
  font-size: 28px;
  text-align: center;

  margin-top: 75px;
  margin-bottom: 144px;

  @media (max-width: 767px) {
    font-size: 27px;
  }

  @media (max-width: 420px) {
    margin-top: 0;
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const Media = ({ data: { allPrismicMedia } }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const Plyr = require('plyr');
      Array.from(document.querySelectorAll('.js-player')).map(p => new Plyr(p));
    }
  });
  const allMedia = allPrismicMedia.edges.map((media, index) => {
    let dimensions =
      media.node.data.image.dimensions !== null
        ? media.node.data.image.dimensions
        : null;
    let imageWidth = dimensions !== null ? dimensions.width : null;
    let imageHeight = dimensions !== null ? dimensions.height : null;
    return (
      <Col col={12} sm={6} key={index}>
        <LazyLoad height={400}>
          <PublicationContainer>
            <Link to={'/media/' + media.node.uid}>
              {media.node.data.image.url && (
                <MediaImage image={media.node.data.image.url} />
              )}
              <MediaTitle
                dangerouslySetInnerHTML={{
                  __html: '<span>Watch </span>' + media.node.data.title.html,
                }}
              ></MediaTitle>
            </Link>
          </PublicationContainer>
        </LazyLoad>
      </Col>
    );
  });
  return (
    <>
      <Helmet title={`Media - Askeaton Contemporary Arts`} />
      <Col col={12} md={10}>
        <PublicationTitle>{'Only in Askeaton'}</PublicationTitle>
        <Row>{allMedia}</Row>
      </Col>
    </>
  );
};

export const query = graphql`
  {
    allPrismicMedia(sort: { order: DESC, fields: [last_publication_date] }) {
      edges {
        node {
          uid
          id
          data {
            text {
              html
            }
            title {
              text
              html
            }
            image {
              url
              dimensions {
                width
                height
              }
            }
            audiourl {
              url
            }
            type
            vimeo_id
          }
        }
      }
    }
  }
`;

export default Media;
